import useDocumentTitle from "../useDocumentTitle";

export default function Projects() {
  useDocumentTitle("Projects");

  return (
    <div className="container">
      <div className="row align-items-start">
        <h3>PUBLIC IMAGE</h3>
        <p>
          My first, experimental short movie about the way we present ourselves
          online.
        </p>
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ebUYNuBMY48"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}
