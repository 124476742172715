import useDocumentTitle from "../useDocumentTitle";

export default function Archive() {
  useDocumentTitle("Archive");

  return (
    <div className="container">
      <div className="row align-items-start">
        <h3>Pathfinders</h3>
        <p className="italic">2012-2022</p>
        <div className="col">
          <p>
            <span className="italic">
              Triangulated Polygon A-star (
              <a
                href="https://github.com/grgomrton/tpastar"
                target="_blank"
                rel="noreferrer"
              >
                tpastar
              </a>
            </span>
            ) and the extension{" "}
            <span className="italic">
              Triangulated Polygon A-star for 2D Triangle Meshes (
              <a
                href="https://github.com/grgomrton/tpamesh"
                target="_blank"
                rel="noreferrer"
              >
                tpamesh
              </a>
            </span>
            ) are two pathfinders originally developed as part of my BSc thesis,
            and later developed in my freetime. They provide a way to apply
            heuristic shortest-path search on triangle maps in 2D. Their
            benefits are their relative simplicity compared to alternative
            methods and that they are readily available under developer-friendly
            terms. If you are interested in solutions for the 3D problem, I
            recommend looking into the MMP algorithm. An example source code is
            available{" "}
            <a
              href="https://code.google.com/archive/p/geodesic"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            . If you want to review current solutions,{" "}
            <a
              href="https://arxiv.org/pdf/2007.10430.pdf"
              target="_blank"
              rel="noreferrer"
            >
              this
            </a>{" "}
            article seems very comprehensive.
          </p>
        </div>
        <div className="col">
          <img
            src="/static/images/tpamesh-transparent-background.png"
            alt="Route found by tpamesh pathfinder between a start and three goal points"
          />
        </div>
      </div>
      <div className="row align-items-start">
        <h3>Kalman-filter</h3>
        <p className="italic">2018</p>
        <div className="col">
          <img
            src="/static/images/kalman-filter.png"
            alt="A graph displaying a gaussian-distribution around the localization estimate"
          />
        </div>
        <div className="col padding-top-in-vertical-alignment">
          <p>
            I made this simple app to gain an understanding of the Kalman-filter
            algorithm, that is widely used to make noisy data more precise. This{" "}
            <a
              href="https://github.com/grgomrton/kalman-filter"
              target="_blank"
              rel="noreferrer"
            >
              application
            </a>{" "}
            uses real world error boundaries for the simulated GPS, and
            simulates an imprecise vehicle to actually see errors.
          </p>
        </div>
      </div>
    </div>
  );
}
