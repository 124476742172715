import { Link } from "react-router-dom";
import useDocumentTitle from "../useDocumentTitle";

export default function Home() {
  useDocumentTitle("Home");

  return (
    <div className="landing">
      <h2>Hey there,</h2>
      <p>Welcome to my website</p>
      <p>
        You can find the most recent updates in the <Link to="/projects">Projects</Link>{" "}
        section
      </p>
    </div>
  );
}
