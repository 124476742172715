import useDocumentTitle from "../useDocumentTitle";

export default function Contact() {
  useDocumentTitle("Contact");

  return (
    <>
      <h3>Contact</h3>
      <p>
        <a href="mailto:marton@martongergo.com">E-mail</a>
      </p>
      <p>
        <a
          href="https://www.instagram.com/marton__gergo/"
          target="_blank"
          rel="noreferrer"
        >
          Instagram
        </a>
      </p>
    </>
  );
}
