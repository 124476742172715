import useDocumentTitle from "../useDocumentTitle";

export default function NotFound() {
  useDocumentTitle("Not found");

  return (
    <>
      <h2>404</h2>
    </>
  );
}
