import { Routes, Link, Route, useLocation } from "react-router-dom";
import Home from "./routes/home";
import Projects from "./routes/projects";
import Contact from "./routes/contact";
import Container from "./routes/container";
import NotFound from "./routes/notFound";
import React, { useEffect, useState } from "react";
import Archive from "./routes/archive";

export default function App() {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) {
      setTransitionStage("fadeOut");
    }
  }, [location, displayLocation]);

  return (
    <>
      <header>
        <div>
          <nav className="nav nav-masthead justify-content-center">
            <Link to="/" className="nav-link py-1 mx-2 px-0">Home</Link>
            <Link to="/projects" className="nav-link py-1 mx-2 px-0">Projects</Link>
            <Link to="/archive" className="nav-link py-1 mx-2 px-0">Archive</Link>
            <Link to="/contact" className="nav-link py-1 mx-2 px-0">Contact</Link>
          </nav>
        </div>
      </header>
      <main
        className={`px-3 flex-shrink-0 ${transitionStage}`}
        onAnimationEnd={() => {
          if (transitionStage === "fadeOut") {
            setTransitionStage("fadeIn");
            setDisplayLocation(location);
          }
        }}
      >
        <Routes location={displayLocation}>
          <Route path="*" element={<Container />}>
            <Route index element={<Home />} />
            <Route path="projects" element={<Projects />} />
            <Route path="archive" element={<Archive />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </main>

      <footer className="mt-auto footer mt-auto py-3 pt-5 text-center fs-6">
      Background image from <a href="https://eol.jsc.nasa.gov/SearchPhotos/photo.pl?mission=ISS062&amp;roll=E&amp;frame=102615" target="_blank" rel="noreferrer">NASA Earth Observatory</a>
      </footer>
    </>
  );
}
